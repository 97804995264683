"use client";

import messages from "~/resources/messages/en-us/components/search/searchField.json";
import SearchBox from "./searchBox";

export interface ISearchFieldProps {
	readonly ghostText: string;
	readonly darkscreenMode?: boolean;
}
export const SearchField: React.FC<ISearchFieldProps> = ({ ghostText, darkscreenMode }) => {
	return (
		<div className="flex items-center justify-center gap-2">
			<div className={`${darkscreenMode ? "text-white" : "text-epic-light-brown"}`}>
				<SearchIcon className="w-8 mx-auto my-2" />
			</div>
			<div className="relative w-full py-2 mx-auto xs:w-96">
				<SearchBox
					id="search_field"
					ghostText={ghostText}
					screenReaderLabel={messages.searchField.clearSearchScreenReaderLabel.value}
					maxLength={100}
					hasMaxWidth
					hasExtraSmallTextSize
					brownBorder
				/>
			</div>
		</div>
	);
};
export default SearchField;

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Layer_2_00000133514844623356221540000012756712028267886013_"
		x={88}
		y={88}
		viewBox="0 0 52.2 52"
		fill="currentColor"
		{...props}
	>
		<g id="Text">
			<path d="M42.3 37c9.4-5.5 12.7-17.7 7.2-27.1C44 .4 31.9-2.8 22.4 2.7c-9.5 5.5-12.7 17.7-7.2 27.1 5.5 9.5 17.7 12.7 27.1 7.2zm-2.2-3.9c-7.4 4.3-16.7 1.8-21-5.6-4.3-7.4-1.8-16.7 5.6-21s16.8-1.8 21 5.6 1.8 16.8-5.6 21z" />
			<path d="M22.2 22.8c1.7-.8 1.8-2 1.8-3.7.1-2.6 1.7-5 4-6.4 2.4-1.4 5.2-1.6 7.7-.6 1.4.6 3.2.9 3.9 0 .7-.9.9-2.5-1.1-3.6-4-2.2-8.9-2.1-12.9.3-3.6 2.2-5.9 6.1-6.1 10.1-.1 2.5.9 4.6 2.7 3.9zM2.6 44.8l8.3-8.3 3.6-3.6c.8-.8 2-.8 2.8 0l1.9 1.9c.8.8.8 2 0 2.8l-3.6 3.6-8.4 8.3-1.7 1.7c-1.3 1.3-3.4 1.3-4.7 0s-1.3-3.4 0-4.7c.2-.1 1.8-1.7 1.8-1.7z" />
		</g>
	</svg>
);
